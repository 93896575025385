import Section from 'components/Section'
import { useMemoizedContentGetter } from 'utils/contentful'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { object } from 'yup'
import { richTextSchema, carouselItemSchema } from 'constants/validation-types'
import styles from './MemberStoriesCarousel.module.scss'
import { Typography, MediaImage } from 'components/Contentful'
import { Grid, Paper } from '@achieve/sunbeam'
import { Carousel } from 'components/Carousels'
import useTheme from 'hooks/useTheme'
import { BLOCKS } from '@contentful/rich-text-types'
import { Eyebrow } from 'components/Eyebrow'

let contentSchema = object({
  title: object({
    textContent: richTextSchema,
  }),
  carousel: object({
    carouselItems: carouselItemSchema,
  }),
})

function Card({
  item: {
    fields: { cardImage, cardImageResponsive, cardText, cardTitleText },
  },
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const theme = useTheme()
  const image = isMobile && cardImageResponsive ? cardImageResponsive : cardImage
  const CardContent = (
    <Grid
      container
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent={{ xs: 'flex-start', lg: 'flex-start' }}
      alignItems={{ xs: 'stretch', lg: 'center' }}
      spacing={{ xs: 4 }}
    >
      <Grid item xs={12} lg={12}>
        <div className={styles['block-img']}>
          <MediaImage
            layout="fill"
            objectFit="cover"
            objectPosition="top right"
            priority={false}
            quality={isMobile ? 75 : 100}
            content={image}
          />
          <Grid
            container
            direction="column"
            justifyContent={{ xxs: 'flex-end', lg: 'center' }}
            className={styles['content']}
          >
            <Typography
              content={cardTitleText}
              color={theme?.sb?.colors?.neutral?.white}
              fontWeight="bold"
              variantOverride={{
                [BLOCKS.PARAGRAPH]: !isMobile ? 'displayM30' : 'displayS20',
              }}
              data-testid="member-story-item-title"
            />
            <Typography
              className={styles['sub-title']}
              content={cardText}
              color={theme?.sb?.colors?.neutral?.white}
              fontWeight="regular"
              variantOverride={{
                [BLOCKS.PARAGRAPH]: !isMobile ? 'displayS10' : 'displayXS30',
              }}
              data-testid="member-story-item-subtitle"
            />
          </Grid>
        </div>
      </Grid>
    </Grid>
  )

  return (
    <Paper elevation={0} className={styles['paper']}>
      <>{CardContent}</>
    </Paper>
  )
}

function MemberStoriesCarousel({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const memoizedContent = useMemoizedContentGetter(content, [
    'title',
    'MemberStoriesCarousel',
    'theme',
  ])
  const achieveTheme = useTheme()
  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn(
      'MoreResourcesCarousel - INVALID CONTENT RESPONSE',
      JSON.stringify(memoizedContent)
    )
    return null
  }

  const {
    title,
    theme,
    MemberStoriesCarousel: { carouselItems },
  } = memoizedContent

  const backgroundColor = theme?.theme[0]
    ? achieveTheme?.sb?.colors?.primary?.highlightBlue
    : achieveTheme?.sb?.colors?.neutral?.white

  return (
    <Section contain={false} className={styles['section']} backgroundColor={backgroundColor}>
      <Eyebrow
        data-testid="featured-story-eyebrow"
        content={title?.textContent}
        variant="displayXS30"
        fontWeight="bold"
        component="h3"
      />
      <Carousel
        carouselItems={carouselItems}
        itemComponent={Card}
        variant="preview"
        idPrefix="member-stories-carousel"
        withIndicators={isMobile}
        classNameIndicators={styles['hero-carousel-wrapper-indicators']}
        classNameControls={styles['hero-carousel-wrapper-controls']}
        classNameActiveDot={styles['carousel-active']}
      />
    </Section>
  )
}

export { MemberStoriesCarousel }
