import Section from 'components/Section'
import { Typography, MediaImage } from 'components/Contentful'
import { Grid } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { Carousel } from 'components/Carousels'
import useTheme from 'hooks/useTheme'
import styles from './FlatCarousel.module.scss'

function Card({
  item: {
    fields: { cardImage, cardTitleText, cardText },
  },
  priority,
}) {
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [quote, attribution] = cardText?.content ?? []
  return (
    <Grid
      container
      direction={{ xs: 'column-reverse', lg: 'row' }}
      justifyContent={{ xs: 'flex-end', lg: 'space-between' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
    >
      <Grid item xs={12} lg={4}>
        <div className={styles['image-container']}>
          <MediaImage content={cardImage} priority={priority} />
        </div>
      </Grid>
      <Grid item xs={12} lg={7}>
        <Grid container direction="column" className={styles['card-text-grid']}>
          <Grid item xs={12}>
            <Typography
              className={styles['carousel-item-title']}
              content={cardTitleText}
              fontWeight="bold"
              variant={isMobile ? 'displayS30' : 'displayM20'}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={styles['carousel-item-quote']}
              content={quote}
              fontWeight="medium"
              variant={isMobile ? 'bodyM10' : 'displayS30'}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              content={attribution}
              fontWeight="regular"
              variant={isMobile ? 'bodyS20' : 'displayS20'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function FlatCarousel({ carouselItems, idPrefix = 'page' }) {
  const theme = useTheme()
  // If the image is nested in a scrollable parent element other than the root document,
  // you will also need to assign the lazyRoot prop
  // https://nextjs.org/docs/api-reference/next/image#lazyroot
  // https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/root

  return (
    <Section
      backgroundColor={theme?.sb?.colors?.primary?.achieveBlue}
      className={styles['section']}
      contain={false}
    >
      <Carousel
        className={styles['flat-carousel-wrapper']}
        carouselItems={carouselItems}
        itemComponent={Card}
        variant="standard"
        idPrefix={`${idPrefix}-testimonial-carousel`}
        withIndicators
      />
    </Section>
  )
}

export { FlatCarousel }
