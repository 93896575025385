import Section from 'components/Section'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { Grid, Paper } from '@achieve/sunbeam'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { BLOCKS } from '@contentful/rich-text-types'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { TitleDivider } from 'components/TitleDivider'
import { Carousel } from 'components/Carousels'
import styles from './PaperCarousel.module.scss'
import useTheme from 'hooks/useTheme'
import { Eyebrow } from 'components/Eyebrow'

function Card({
  idPrefix,
  item: {
    fields: {
      cardImage,
      cardText,
      cardLink: {
        fields: { linkText, linkHref, downloadFile, ...cardLink },
      },
    },
  },
  priority,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.xl)
  const [leadText, heading, supportingText] = cardText?.content ?? []
  const CardContent = (
    <Grid
      container
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent={{ xs: 'flex-start', lg: 'flex-start' }}
      alignItems={{ xs: 'stretch', lg: 'center' }}
      className={styles['outer-grid-container']}
    >
      <Grid item xs={12} lg={7} className={styles['grid-item-image']}>
        <MediaImageStandard
          content={cardImage}
          layout="fill"
          objectFit="cover"
          height={isMobile ? 300 : 341}
          objectPosition="top"
          priority={priority}
          className={styles.image}
        />
      </Grid>
      <Grid container item xs={12} lg={5}>
        <Grid item xs={12}>
          <Typography
            content={leadText}
            data-testid={`${idPrefix}-lead-text`}
            className={styles['lead-text']}
            variant="bodyS20"
          />
        </Grid>
        <Grid item xs={12}>
          {downloadFile?.fields?.file?.url ? (
            <Typography
              content={heading}
              data-testid={`${idPrefix}-heading-text`}
              variant="displayS20"
              className={styles['heading-text']}
              fontWeight="medium"
            />
          ) : (
            <AchieveLink href={linkHref} className={styles['title-link']}>
              <Typography
                content={heading}
                data-testid={`${idPrefix}-heading-text`}
                variant="displayS20"
                className={styles['heading-text']}
                fontWeight="medium"
              />
            </AchieveLink>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            content={supportingText}
            data-testid={`${idPrefix}-supporting-text`}
            fontWeight={isMobile ? 'light' : 'regular'}
            className={styles['supporting-text']}
            variant="bodyS30"
          />
        </Grid>
        <Grid item xs={12}>
          {downloadFile?.fields?.file?.url ? (
            <AchieveLink
              href={downloadFile?.fields?.file?.url}
              target="blank"
              download
              className={styles['link']}
            >
              <Typography
                className={styles.title}
                content={cardLink?.title}
                variant="displayS10"
                fontWeight="bold"
                component="h3"
              />
            </AchieveLink>
          ) : (
            <AchieveLink href={linkHref} className={styles['link']}>
              <Typography
                content={linkText}
                fontWeight="bold"
                variant={isMobile ? 'displayXS30' : 'displayS11CTA'}
                styleOverride={{
                  [BLOCKS.PARAGRAPH]: {
                    marginTop: '1em',
                  },
                }}
              />
            </AchieveLink>
          )}
        </Grid>
      </Grid>
    </Grid>
  )

  return isMobile ? (
    <>{CardContent}</>
  ) : (
    <Paper elevation={0} className={styles['paper']}>
      <>{CardContent}</>
    </Paper>
  )
}

function PaperCarousel({ title, carouselItems, theme, idPrefix = 'page', eyebrow }) {
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const sectionIdPrefix = `${idPrefix}-more-resources-carousel`
  const achieveTheme = useTheme()
  const backgroundColor =
    theme?.theme[0] == 'light-blue'
      ? achieveTheme?.sb?.colors?.primary?.highlightBlue
      : achieveTheme?.sb?.colors?.neutral?.white
  const variant = {
    title: isMobile ? 'displayS30' : 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }
  return (
    <Section contain={false} className={styles['section']} backgroundColor={backgroundColor}>
      {eyebrow && (
        <Eyebrow
          content={eyebrow?.textContent}
          variant="displayXS30"
          fontWeight="bold"
          component="h3"
        />
      )}
      <TitleDivider
        className={styles['paper-carousel-title-divider']}
        title={title}
        isMobile={isMobile}
        data-testid={`${idPrefix}-title`}
        variants={variant}
      />
      <Carousel
        carouselItems={carouselItems}
        itemComponent={Card}
        variant="preview"
        idPrefix={sectionIdPrefix}
        className={styles['shadow-selected']}
        currentClassName={styles['selected-item']}
      />
    </Section>
  )
}

export { PaperCarousel }
