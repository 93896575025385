import { Typography } from 'components/Contentful/Typography'
import { AchieveLink } from 'components/AchieveLink'
import styles from './Pills.module.scss'
import { useRouter } from 'next/router'
import React from 'react'

function Pill({ category, article, pathOverride }) {
  const router = useRouter()

  const urlMaker = (str) => {
    const getPath = () => {
      let fullPath = router.pathname
      return fullPath.split('[')?.[0]
    }

    const queryStripper = () => {
      router.query = {}
    }

    queryStripper()
    const url = article ? pathOverride : getPath()
    return `${url}${str.split(' ').join('-').toLowerCase()}`
  }

  const makePill = (category) => {
    return (
      <AchieveLink href={`${urlMaker(category)}`} className={styles['pill-anchor']}>
        <Typography
          data-testid="pill"
          className={styles['pill-content']}
          color="primary"
          fontWeight="medium"
          variant={'displayXS30'}
          content={category}
        />
      </AchieveLink>
    )
  }

  return makePill(category)
}

export { Pill }
